.mapContainer {
    .mapContainerWrap {
        position: relative;
        height: 100%;
         .mapContainer__mapBanner{
            width: 100%;
            height: auto;
         }
         .mapContainer__title{
            width: 100%;
            text-align: center;
            font-size: 40px;
            font-family: PingFangSC-Semibold;
            color: #00FF5A;
            position: absolute;
            left: 0;
            top: 18%;
         }
        .light__iconGreen {
            position: absolute;
            width: 28px;
            height: 28px;
            background: url(../../../../assets/about/light_green.png) no-repeat;
            background-size: cover;
            opacity: .6;
            transition: all .8s ease-in-out;

            &.light {
                opacity: 1;
            }
        }

        .light__iconWhite {
            position: absolute;
            width: 30px;
            height: 30px;
            background: url(../../../../assets/about/light_white.png) no-repeat;
            background-size: cover;
            transition: all 1.5s ease-in-out;
            opacity: .7;

            &.light {
                opacity: 1;
            }
        }

        .light__iconGolden {
            position: absolute;
            width: 30px;
            height: 30px;
            background: url(../../../../assets/about/light_golden.png) no-repeat;
            background-size: cover;
            opacity: .8;
            transition: all 1s ease-in-out;

            &.light {
                opacity: 1;
            }
        }

        .text__pos {
            position: absolute;
            font-size: 12px;
            color: #fff;
            transform: scale(.6);
        }
    }

    .mapContainer__footer {
        padding: 15px 25px;
        text-align: center;
        font-size: 12px;
        line-height: 22px;
        color: #FFFFFF;
    }

}