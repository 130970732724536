.qualityPage__carouselWrap {
    padding: 15px 0px 15px 16px;

    .qualityPage__carousel {
        position: relative;

        .qualityPage__carouselItem {
            display: flex;
            justify-content: space-between;
            width: 120%;
            .qualityPage__carouselItem-pic {
                margin-right: 10px;
                width: 53%;
                height: auto;
            }
        }
    }
}

.carousel.carousel-slider {
    overflow: visible !important;
}

