
.productPage{
    padding-top: 100px;
    padding-bottom: 60px;
    .productPage__foot{
    	padding: 0 15px;
    	text-align: center;
    	font-size: 14px;
    	line-height: 24px;
    }
}