.qualityPage__honorsWrap {
    padding-bottom: 50px;
    margin: 0 15px;
    .qualityPage__honorsTop {
        padding-top: 40px;
        .qualityPage__honorsTitle {
            font-size: 30px;
            line-height: 40px;
            font-family: PingFangSC-Semibold;
            color: #FFFFFF;
        }

        .qualityPage__honorsDesc {
            padding: 15px 0;
            display: inline-block;
            font-size: 12px;
            font-family: PingFang SC;
            font-weight: 400;
            color: #FFFFFF;
            line-height: 22px;
            text-align: justify;
        }
        &.en{
            display: block;
             .qualityPage__honorsDesc {
                 font-size: 12px;
                 text-align: left;
             }
        }
    }

    .qualityPage__honorsItemsWrap {
        margin-top: 10px;
        display: flex;
        justify-content: space-around;
        flex-wrap: wrap;
       
    }
}






