.headerContainer {
    position: fixed;
    left: 0;
    top: 0;
    z-index: 100;
    width: 100vw;
    height: auto;
    background: rgba(0, 0, 0, .7);
    opacity: 1;
    transition: all .4s ease-in;
    -moz-transition: all .4s ease-in;
    -webkit-transition: all .4s ease-in;

    &.out {
        opacity: 0;
    }
}

.headerContainer__row {
    padding: 40px 15px 10px 15px;
    display: flex;
    justify-content: space-between;
    justify-items: flex-start;

    .headerContainer__menuIcon {
        margin-top: 5px;
        flex: 1;

        .headerContainer__menu-image {
            width: 15px;
            height: 15px;
        }
    }

    .headerContainer__logo {
        margin-top: 5px;
        flex: 1;
        text-align: center;

        .headerContainer__logo-image {
            width: 92px;
            height: 14px;
        }

    }

    .headerContainer__language {
        flex: 1;
        text-align: right;

        .headerContainer__language-link {
            font-size: 12px;
            color: #888888;
            cursor: pointer;
            vertical-align: middle;

            &.active {
                color: #00FF5A;
            }
        }

        .headerContainer__language-span {
            display: inline-block;
            padding: 0 5px;
            font-size: 12px;
            color: #888888;
            vertical-align: middle;
        }

        .headerContainer__language-ico {
            margin-right: 6px;
            vertical-align: middle;
            width: 14px;
            height: 14px;
        }
    }
}

.headerOverplay {
    position: fixed;
    left: 0;
    top: 0;
    z-index: 1000;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 1);
    opacity: 1;
    transition: all .4s ease-in;
    -moz-transition: all .4s ease-in;
    -webkit-transition: all .4s ease-in;
    overflow-y: auto;

    .headerContainer__row {
        padding: 10px 15px 10px 15px;
        margin-top: 30px;
        background: #12161B;
    }


    .headerContainer__menu {
        margin-top: 20px;
        .headerContainer__menuCol {
            padding: 20px 20px;
            height: auto;

            .headerContainer__menu-title {
                font-size: 20px;
                font-family: PingFangSC-Semibold;
                color: #00FF5A;
            }

            .headerContainer__items {
                margin-top: 15px;
                .headerContainer__menu-itemLink {
                    display: inline-block;
                    margin-right: 15px;
                    font-size: 12px;
                    line-height: 26px;
                    color: #FFFFFF;
                }
            }
        }

        &.en {
            .headerContainer__menuCol {
                padding: 15px 20px;

                .headerContainer__menu-title {
                    font-size: 18px;
                }

                .headerContainer__items {
                    .headerContainer__menu-itemLink {
                        display: block;
                        font-size: 12px;
                    }
                }
            }

        }
    }
}