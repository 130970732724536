.aboutIntroduce {
    .aboutIntroduce__row {

        .aboutIntroduce__intro {
            padding: 15px;

            .aboutIntroduce__info {
                margin-top: 15px;
                .aboutIntroduce__info-p {
                    margin-bottom: 40px;
                    font-size: 14px;
                    color: #FAFCF9;
                    line-height: 24px;
                    text-align: justify;
                }
                &.en {
                    .aboutIntroduce__info-p {
                        line-height: 24px;
                        font-size: 12px;
                        max-height: 360px;
                        overflow-y: auto;

                        &::-webkit-scrollbar {
                            width: 5px;
                            height: 10px;
                            background-color: rgba(0, 255, 90, .1);
                            border-radius: 5px;
                        }

                        &::-webkit-scrollbar-thumb {
                            background-color: rgba(0, 255, 90, .3);
                            border-radius: 10px;
                            -webkit-box-shadow: inset1px1px0rgba(0, 0, 0, .1);
                        }
                    }
                }
            }
        }

        .aboutIntroduce__items {
            padding: 0 30px;
            display: flex;
            justify-content: space-around;
            flex-wrap: wrap;

            .aboutIntroduce__itemBg {
                width: 145px;
                height: 100px;

                .aboutIntroduce__itemLabel {
                    padding: 5px 10px;
                    text-align: right;
                    font-size: 20px;
                    color: #FFFFFF;
                }

                &.design {
                    background: url(../../../../assets/about/introduce_design.png) no-repeat;
                    background-size: 100% 100%;
                }

                &.develop {
                    background: url(../../../../assets/about/introduce_develop.png) no-repeat;
                    background-size: 100% 100%;
                }

                &.produces {
                    background: url(../../../../assets/about/introduce_produces.png) no-repeat;
                    background-size: 100% 100%;
                }

                &.sale {
                    background: url(../../../../assets/about/introduce_sale.png) no-repeat;
                    background-size: 100% 100%;
                }
            }
        }

    }
}