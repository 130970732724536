.homePage {
    padding-top: 80px;
    padding-bottom: 60px;
    overflow-x: hidden;
    .homePage__videoWrap {
        width: 100%;
        height: 220px;
    }

    .homePage__intro {
        margin-top: 0px;
    }

    .homePage__ramonster {
        padding-top: 60px;
    }

    .homePage__product {
        padding-top: 60px;
    }

    .homePage__devlop {
        padding-top: 0px;
    }
    .homePage__honors{
       padding-top: 60px;  
    }

    .homePage__cooper {
        padding-top: 80px;
    }

    .homePage__readMore {
        margin-top: 20px;
        text-align: center;

        .homePage__readMore-link {
            display: inline-block;
            width: 150px;
            height: 40px;
            background: rgba(0, 155, 65, 0);
            border: 1px solid #00FF1E;
            font-size: 12px;

            color: #FAFCF9;
            line-height: 40px;
            transition: all ease-out 1s;
            &:hover {
                border: 2px solid #fff;
            }
        }
    }
}