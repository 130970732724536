.aboutCompany {
    margin-top: 50px;
    margin-bottom: 30px;

    .aboutCompany__row {
        margin: 0 15px;
        .aboutCompany__item {
            margin-top: 10px;
            padding: 15px;
            background: rgba(18, 21, 23, 1);
            border: 0px solid #000000;
            cursor: pointer;

            .aboutCompany__itemName {
                font-size: 16px;
                font-family: PingFang SC;
                font-weight: 600;
                color: #FFFFFF;
                line-height: 24px;
            }

            .aboutCompany__address {
                margin-top: 10px;

                .aboutCompany__address-icon {
                    display: inline-block;
                    width: 22px;
                    height: 22px;
                    background: url(../../../../assets/about/icon_contact_address.png) no-repeat;
                    background-size: 100% 100%;
                    vertical-align: middle;
                }

                .aboutCompany__address-detail {
                    margin-left: 10px;
                    font-size: 14px;
                    font-family: PingFang SC;
                    font-weight: 400;
                    color: #FFFFFF;
                    vertical-align: middle;
                }
            }

            .aboutCompany__subAddress {
                margin-top: 20px;
                font-size: 12px;
                font-family: PingFang SC;
                font-weight: 400;
                color: #FFFFFF;
            }

            &:hover {
                background-color: #00FF5A;

                .aboutCompany__itemName {
                    color: #000000;
                }

                .aboutCompany__address {
                    .aboutCompany__address-icon {
                        background: url(../../../../assets/about/icon_company_address.png) no-repeat;
                        background-size: 100% 100%;
                    }

                    .aboutCompany__address-detail {
                        color: #000;
                    }
                }

                .aboutCompany__subAddress {
                    color: #000;
                }

            }
        }
    }
}




