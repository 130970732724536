.productLPDDR {
    margin-top: 60px;
    margin-bottom: 50px;
    padding: 0 15px;

    .productLPDDR__top {
        .productLPDDR__topTitle {
            font-size: 30px;
            font-family: PingFangSC-Semibold;
            color: #FFFFFF;
        }

        .productLPDDR__topDesc {
            margin-top: 15px;
            font-size: 14px;
            line-height: 24px;
            color: #FFFFFF;
            text-align: justify;
        }

        .productLPDDR__iconsRow {
            margin-top: 15px;
            display: flex;
            justify-content: flex-start;

            .productLPDDR__icon {
                margin-right: 15px;
                text-align: center;

                .productLPDDR__icon-pic {
                    width: 25px;
                    height: 25px;
                }

                .productLPDDR__icon-label {
                    font-size: 12px;
                    color: #FFFFFF;

                    &.en {
                        font-size: 12px;
                        transform: scale(0.7);
                    }
                }
            }
        }

        .productLPDDR__middlePic {
            margin-top: 20px;
            .productLPDDR__middlePic-img {
                width: 64%;
                height: auto;
            }
        }

        &.en {

            .productLPDDR__topTitle {
                font-size: 22px;
            }

            .productLPDDR__topDesc {
                font-size: 12px;
            }

            .productLPDDR__iconsRow {
                .productLPDDR__icon {
                    margin-right: 20px;
                }
            }
        }
    }


    .productLPDDR__foot {
        margin-top: 30px;
        display: flex;
        justify-content: space-between;

        .productLPDDR__footItem {
            width: 45%;
            .productLPDDR__footItemPic {
                 width: 100%;
                .productLPDDR__footItemPic-img {
                    width: 100%;
                    height: auto;
                }
            }

            .productLPDDR__footItem-label {
                margin-top: -5px;
                width: 95%;
                padding-left: 5%;
                height: 30px;
                line-height: 30px;
                background: #12161B;
                border: 0px solid #000000;
                font-size: 12px;
                color: #FFFFFF;
                &.en{
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }
        }
    }
}