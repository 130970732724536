.homePage__productCarouselWrap {
    .homePage__productTitle {
        padding: 0 15px;
        .homePage__productTitle-p {
            font-size: 30px;
            line-height: 40px;
            font-family: PingFangSC-Semibold;
            color: #FFFFFF;
            &.en{
                font-size: 20px;
            }
        }
    }

    .homePage__productCarousel {
        margin-top: 30px;
        .homePage__productCarouselItem {
            padding-bottom: 40px;
            .homePage__productCarouselItem-pic {
                width: 100%;
                height: auto;
            }
            .homePage__productCarouselItemFoot{
                margin-top: -35px;
                .homePage__productCarouselItemFoot-pic{
                    width: 100%;
                    height: auto;
                }
            }

        }
    }
}


.carousel .control-dots .dot{
    background-color: #324B4F;
    width: 5px;
    height: 5px;
}

.carousel .control-dots .selected{
    background-color: #00FF5A;
    border-radius: 2px;
    width: 15px;
    height: 5px;
}