.homePage__honorsWrap {
    padding: 0 15px;
    .homePage__honorsTop {
        .homePage__honorsTitle-pic{
            width: 100%;
            height: auto;
        }
        .homePage__honorsTitle {
            margin-top: -30px;
            font-family: PingFangSC-Semibold;
            color: #FFFFFF;
            font-size: 30px;
            line-height: 40px;
             &.en{
              font-size: 22px;  
            }
        }
    }

    .homePage__honorsDescWrap {
        padding: 15px 0;
        .homePage__honorsDesc {
            font-size: 12px;
            color: #FFFFFF;
            line-height: 22px;
        }
    }

    .homePage__honorsItemsWrap {
        margin-top: 10px;
        display: flex;
        justify-content: space-around;
        flex-wrap: wrap;
    }
}
