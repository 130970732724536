.aboutHistory {
    margin-top: 50px;
    text-align: center;

    .aboutHistory__title {
        font-size: 30px;
        font-family: PingFangSC-Semibold;
        color: #FFFFFF;
    }

    .aboutHistory__list {
        margin-top: 20px;
        text-align: left;

        .aboutHistory__item {
            position: relative;
            margin-top: -8px;
            .aboutHistory__itemImg {
                width: 100%;
                height: auto;
            }

            .aboutHistory__itemDesc {
                position: absolute;
                left: 4%;
                top: 42%;
                width: 90%;
                font-size: 14px;
                color: #FFFFFF;
                line-height: 19px;
            }
        }

        &.en {
            .aboutHistory__item {
                .aboutHistory__itemDesc {
                    font-size: 12px;
                }
            }
        }
    }
}
