:root {
  font-family: PingFangSC-Regular, AlibabaPuHuiTi-Regular, Arial,Helvetica, sans-serif;
  line-height: 1.5;
  font-weight: 400;
  color-scheme: light dark;
  color: #ffffff;
  background-color: #000000;
  max-width: 640px;
  min-width: 320px;
  min-height: 100vh;
  margin: 0 auto;

}
::-webkit-scrollbar {
  width: 1px;
  height: 0px;
}
/* 清除浮动 */
.clearfix:before,.clearfix:after {
  content:"";
  display:table;
}
.clearfix:after {
  clear:both;
  overflow:hidden;
}
body,div,dl,dt,dd,ul,ol,li,h1,h2,h3,h4,h5,h6,pre,code,form,fieldset,legend,input,textarea,p,blockquote,th,td,hr,button,article,aside,details,figcaption,figure,footer,header,hgroup,menu,nav,section,input {
  margin:0;
  padding:0;
}

input,select,textarea {
  font-size:100%;
}
/* 去掉 table cell 的边距并让其边重合 */
table {
  border-collapse:collapse;
  border-spacing:0;
}
/* ie bug：th 不继承 text-align */
th {
  text-align:inherit;
}
/* 去除默认边框 */
fieldset,img {
  border:none;
}
/* ie6 7 8(q) bug 显示为行内表现 */
iframe {
  display:block;
}
/* 去掉 firefox 下此元素的边框 */
abbr,acronym {
  border:none;
  font-variant:normal;
}
/* 一致的 del 样式 */
del {
  text-decoration:line-through;
}
address,caption,cite,code,dfn,em,th,var {
  font-style:normal;
  font-weight:500;
}

ol,ul {
  list-style:none;
}
a {
  margin: 0;
  padding: 0;
  text-decoration: none
}
