.homePage__introWrap {
    padding: 0 15px;

    .homePage__introSlogan {
        position: relative;

        .homePage__introSlogan-img {
            width: 100%;
            height: auto;
            z-index: -1;
        }

        .homePage__intro-title {
            position: absolute;
            top: 55%;
            right: 0px;
            width: 120px;
            margin-top: -60px;
            font-size: 50px;
            line-height: 60px;
            font-family: PingFangSC-Semibold;
            &.en{
               margin-top: 20px;
               left: 50%;
               font-size: 30px;
            }
        }
    }

    .homePage__introDesc {
        .homePage__introDesc-p {
            font-size: 12px;
            line-height: 22px;
            text-align: justify;
        }

        &.en {

            .homePage__introDesc-p {
                max-height: 200px;
                overflow-y: auto;

                &::-webkit-scrollbar {
                    width: 5px;
                    height: 10px;
                    background-color: rgba(0, 255, 90, .1);
                    border-radius: 5px;
                }

                &::-webkit-scrollbar-thumb {
                    background-color: rgba(0, 255, 90, .3);
                    border-radius: 10px;
                    -webkit-box-shadow: inset1px1px0rgba(0, 0, 0, .1);
                }
            }
        }
    }


    .homePage__introRow {
        margin-top: -80px;
        display: flex;

        .homePage__introLeft {
            .homePage__introLeft-image {
                width: 608px;
                height: auto;
            }
        }

        .homePage__introRight {
            margin-left: 100px;
            margin-right: 15px;

            .homePage__intro-title {
                font-size: 120px;
                font-family: PingFangSC-Semibold;
            }

            .homePage__intro-info {
                margin-top: 120px;

                .homePage__intro-info-p {
                    margin-bottom: 60px;
                    font-size: 14px;
                    font-weight: 400;
                    color: #FAFCF9;
                    line-height: 40px;
                    flex-wrap: wrap;
                }

                &.en {
                    margin-top: 80px;

                    .homePage__intro-info-p {
                        line-height: 36px;
                    }
                }
            }
        }
    }
}