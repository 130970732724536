.homePage__developCarouselWrap {
    position: relative;
    .developCarousel__content {
        .homePage__developTitle {
            padding: 15px;
            padding-top: 60px;
            .homePage__developTitle-p {
                font-size: 30px;
                line-height: 40px;
                font-family: PingFangSC-Semibold;
                color: #FFFFFF;
                &.en{
                    font-size: 20px;
                }
            }
        }

        .homePage__developCarousel {
            position: relative;

            .homePage__developCarouselItem {
                .homePage__developCarouselItem-pic {
                    width: 100%;
                    height: auto;
                }
            }
        }
    }

    .developCarousel__title {
         padding: 15px;
        font-size: 20px;
        color: #fff;
        font-family: PingFangSC-Semibold;
    }

    .developCarousel__desc {
         padding: 0px 15px;
        text-align: left;
        font-size: 12px;
        color: #fff;
        line-height: 22px;
    }


}