.qualityMapContainer{
    position: relative;
    .qualityMap_pic{
        width: 100%;
        height: auto;
    }
   
}


