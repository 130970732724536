  .homePage__honorsItem {
            text-align: center;
            margin-bottom: 20px;
            width: 142px;
            .homePage__honorsItemCert {
                width: 100%;
                height: auto;

                .homePage__honorsItemCert-img {
                    width: 100%;
                    height: 100%;
                }
            }

            .homePage__honorsItemDesc {
                font-size: 12px;
                font-family: PingFang SC;
                color: #FAFCF9;
                line-height: 17px;
                transform: scale(.9);
                &.en{
                 transform: scale(.8);  
                }
            }
        }