.footerContainer {
    background-color: rgba(0, 0, 0, 1);
    border-top: 1px solid #00FF5A;
    .footerContainer__copyright {
        padding: 20px;
        text-align: center;
        font-size: 12px;
        line-height: 18px;
        color: #888888;
        a{
            color: #888888;  
        }
    }
}


