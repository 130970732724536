.aboutContact {
    padding-top: 50px;

    .aboutContact__map {
        position: relative;

        .aboutContact__title {
            font-size: 30px;
            font-family: PingFangSC-Semibold;
            color: #FFFFFF;
            text-align: center;
        }

        .aboutContact__mapImg {
            position: relative;
            margin-top: -30px;
            width: 100%;
            height: auto;
            z-index: -1;
        }

        .lightsWrap {
            height: 100%;

            .light__iconWhite {
                position: absolute;
                width: 25px;
                height: 25px;
                background: url(../../../../assets/about/light_white.png) no-repeat;
                background-size: cover;
                opacity: .6;
                transition: all 1s ease-in-out;

                &.light {
                    opacity: 1;
                }
            }

            .light__iconGolden {
                position: absolute;
                width: 25px;
                height: 25px;
                background: url(../../../../assets/about/light_golden.png) no-repeat;
                background-size: cover;
                opacity: .5;
                transition: all 1.5s ease-in-out;

                &.light {
                    opacity: 1;
                }
            }

            .text__pos {
                position: absolute;
                font-size: 12px;
                color: #fff;

            }
        }
    }

    .aboutContact__company {
        width: 290px;
        margin: -20px auto 0 auto;
        text-align: left;

        .aboutContact__companyName {
            font-size: 16px;
            font-family: PingFangSC-Semibold;
            color: #00FF5A;
            text-align: center;
        }

        .aboutContact__address {
            display: flex;
            margin-top: 10px;
            margin-left: 20px;
            .aboutContact__address-icon {
                display: inline-block;
                width: 14px;
                height: 14px;
                background: url(../../../../assets/about/icon_contact_address.png) no-repeat;
                background-size: 100% 100%;
            }

            .aboutContact__addressRight {
                flex: 1;
                margin-left: 10px;

                .aboutContact__address-detail {
                    font-size: 12px;
                    color: #FFFFFF;
                }
            }
        }

        .aboutContact__email {
            display: flex;
            margin-top: 10px;
            margin-left: 20px;
            .aboutContact__email-icon {
                display: inline-block;
                width: 14px;
                height: 14px;
                background: url(../../../../assets/about/icon_contact_email.png) no-repeat;
                background-size: 100% 100%;
            }

            .aaboutContact__emailDetail {
                margin-left: 10px;
                font-size: 12px;
                color: #FFFFFF;
            }
        }
    }
}