.aboutCulture {
    margin-top: 50px;

    .aboutCulture__top {

        .aboutCulture__title {
            text-align: center;
            font-size: 30px;
            font-family: PingFangSC-Semibold;
            color: #FFFFFF;
        }
    }

    .aboutCulture__contentRow {
        margin-top: 30px;
        display: flex;
        flex-wrap: wrap;
        .aboutCulture__col {
            margin-top: -8px;
            position: relative;
            text-align: center;
            cursor: pointer;
            width: 50%;

            .aboutCulture__colImg {
                width: 100%;
                height: auto;
            }

            .aboutCulture__content {
                position: absolute;
                width: 100%;
                top: 25%;

                .aboutCulture__subtitle {
                    font-size: 30px;
                    font-family: PingFangSC-Semibold;
                    color: #FFFFFF;
                }

                .aboutCulture__desc {
                    margin-top: 0px;
                    font-size: 14px;
                    line-height: 18px;
                    color: #FFFFFF;
                    margin-left: -2%;
                    width: 104%;
                    transform: scale(.9);
                }
            }

            &:hover {
                .aboutCulture__content {
                    .aboutCulture__subtitle {
                        color: #00FF5A;
                    }

                    .aboutCulture__desc {
                        color: #00FF5A;
                    }
                }
            }
        }
    }

    &.en {
        .aboutCulture__contentRow {
            .aboutCulture__col {
                .aboutCulture__content {
                    .aboutCulture__subtitle {
                        font-size: 20px;
                    }

                    .aboutCulture__desc {
                        margin-left: -5%;
                        width: 110%;
                        font-size: 12px;
                        transform: scale(.8);
                    }
                }
            }
        }
    }
}

