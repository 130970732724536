.productBanner {
    position: relative;

    .productBanner__pic {
        width: 100%;
        height: auto;
    }

    .productBanner__title {
        position: absolute;
        top: 30%;
        left: 15px;

        .productBanner__title-data {
            font-size: 30px;
            line-height: 40px;
            color: #00FF5A;
            font-family: PingFangSC-Semibold;
        }

        &.en {
            .productBanner__title-data {
                font-size: 18px;
            }
        }
    }
}
