.homePage__ramonsterWrap {
    padding: 0 15px;

    .homePage__ramonsterSlogan {
        position: relative;

        .homePage__ramonsterSlogan-img {
            width: 100%;
            height: auto;
            z-index: -1;
        }

        .homePage__ramonster-title {
            position: absolute;
            top: 60%;
            right: 0;
            margin-top: -50px;
            width: 110px;
            line-height: 55px;
            font-size: 50px;
            font-family: PingFangSC-Semibold;
            &.en{
               left: 50%;
               margin-top: -20px;
               font-size: 25px;
            }
        }

    }

    .homePage__ramonsterRow {
         margin-top: 20px;
        .homePage__ramonsterContent {

            .homePage__ramonster-info {

                .homePage__ramonster-info-p {
                    font-size: 12px;
                    color: #FAFCF9;
                    line-height: 22px;
                    flex-wrap: wrap;
                    text-align: justify;
                }
            }

            .homePage__ramonsterMore {
                margin-top: 30px;
                text-align: center;

                .homePage__ramonsterMore-link {
                    display: inline-block;
                    width: 150px;
                    height: 40px;
                    background: rgba(0, 155, 65, 0);
                    border: 1px solid #00FF1E;
                    font-size: 12px;
                    font-weight: 400;
                    color: #FAFCF9;
                    line-height: 40px;
                    transition: all ease-out 1s;

                    &:hover {
                        border: 2px solid #fff;
                    }
                }
            }
        }
    }
}