.homePage__cooperWrap {
    padding: 0 15px;
    .cooper__title {
        text-align: center;
        font-size: 30px;
        font-family: PingFangSC-Semibold;
        &.en{
          font-size: 22px;  
        }
    }
    .cooper__picWrap{
        margin-top: 15px;
        .cooper__pic{
            width: 100%;
            height: auto;
        }
    }
}
